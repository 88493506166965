import React, { useState, useEffect, useRef } from "react";
import "./SecondSection.css";

const SecondSection = ({ scrollToFifthSection }) => {

  const [isMobile, setIsMobile] = useState(false);

  // Check if the device is mobile
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);
    checkScreenSize();

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Images for larger and smaller devices
  const Wall = isMobile
    ? `${process.env.PUBLIC_URL}/assets/second-section/wall.png`
    : `${process.env.PUBLIC_URL}/assets/second-section/wall.png`;
  const Ceiling = isMobile
    ? `${process.env.PUBLIC_URL}/assets/second-section/ceiling.png`
    : `${process.env.PUBLIC_URL}/assets/second-section/ceiling.png`;
  const Facade = isMobile
    ? `${process.env.PUBLIC_URL}/assets/second-section/facade.png`
    : `${process.env.PUBLIC_URL}/assets/second-section/facade.png`; 

    const arrowRight = `${process.env.PUBLIC_URL}/assets/second-section/arrow-right.png`;
    const arrowLeft = `${process.env.PUBLIC_URL}/assets/second-section/arrow-left.png`;
    const OYO = `${process.env.PUBLIC_URL}/assets/second-section/oyo.png`;
    const Raddison = `${process.env.PUBLIC_URL}/assets/second-section/radisson.png`;
    const Hyundai = `${process.env.PUBLIC_URL}/assets/second-section/hyundai.png`;
    const Infosys = `${process.env.PUBLIC_URL}/assets/second-section/infosys.png`;
    const McDonald = `${process.env.PUBLIC_URL}/assets/second-section/mcdonalds.png`;


  return (
    <div className="second-section">
      <div className="arrow-left">
        <img src={arrowLeft} alt="Left Arrow" />
      </div>
      <h2>Find Your Perfect Fit</h2>
      <div className="product-section">
        <div className="product-card">
          <div className="image-container">
            <img src={Wall} alt="Wall" />
          </div>
          <button className="enquiry-button"  onClick={scrollToFifthSection}>Enquiry</button>
        </div>
        <div className="product-card">
          <div className="image-container">
            <img
              src={Ceiling}
              alt="Ceiling"
            />
          </div>
          <button className="enquiry-button"  onClick={scrollToFifthSection}>Enquiry</button>
        </div>
        <div className="product-card">
          <div className="image-container">
            <img
              src={Facade}
              alt="Facade"
            />
          </div>
          <button className="enquiry-button"  onClick={scrollToFifthSection}>Enquiry</button>
        </div>
      </div>
      <div className="arrow-right">
        <img src={arrowRight} alt="Right Arrow" />
      </div>
        <div className="trusted-section">
            <div className="arrow-left trusted-arrow">
                <img src={arrowLeft} alt="Left Arrow" />
            </div>
                <h3>Trusted Partners Across Industries</h3>
                <p>Chosen By The Best</p>
                    <div className="trusted-logos">
                        <img src={OYO} alt="OYO" />
                        <img src={Raddison} alt="Radisson Blu" />
                        <img src={Hyundai} alt="Hyundai" />
                        <img src={Infosys} alt="Infosys" />
                        <img src={McDonald} alt="McDonald's" />
                    </div>
            <div className="arrow-right trusted-arrow">
                <img src={arrowRight} alt="Right Arrow" />
            </div>        
        </div>
    </div>
  );
};

export default SecondSection;
