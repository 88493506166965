import React from "react";
import "./LandingSection.css";

const LandingSection = () => {

  const backgroundImage = `${process.env.PUBLIC_URL}/assets/hero-section.png`;
  const backgroundImageSmall = `${process.env.PUBLIC_URL}/assets/hero-section.png`;
  const Warranty = `${process.env.PUBLIC_URL}/assets/warranty.png`;
  const Logo = `${process.env.PUBLIC_URL}/assets/logo.png`;

  return (
    <section className="landing-section">
      <div className="row">
        {/* Left Section */}
        <div className="image-container-landing">
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={backgroundImageSmall}
            />
            <img
              src={backgroundImage}
              alt="Decorative Wall Panel"
              className="main-image"
            />
          </picture>
          <img
            src={Warranty}
            alt="Warranty Badge"
            className="warranty-image"
          />
        </div>

        {/* Right Section */}
        <div className="content-container">
          <div className="logo-container">
            <img src={Logo} alt="Logo" className="logo" />
          </div>
          <h1>Innovative Decorative</h1>
          <h2>Wall & Ceiling Panels</h2>
          <div className="form-container">
            <h3>Get <span className="highlight">FREE</span> Sample</h3>
            <form>
              <input type="text" placeholder="Name*" className="form-input" />
              <input type="text" placeholder="Phone No.*" className="form-input" />
              <button type="submit" className="form-button">Next</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;