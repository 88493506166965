import React from "react";
import "./ThirdSection.css";

const ThirdSection = () => {
  const arrowRight = `${process.env.PUBLIC_URL}/assets/second-section/arrow-right.png`;
  const arrowLeft = `${process.env.PUBLIC_URL}/assets/second-section/arrow-left.png`;
  const Infosys = `${process.env.PUBLIC_URL}/assets/third-section/infosys.png`;
  const McDonalds = `${process.env.PUBLIC_URL}/assets/third-section/mcdonalds.png`;
  const Wankhede = `${process.env.PUBLIC_URL}/assets/third-section/wankhede.png`;
  const CostaCoffee = `${process.env.PUBLIC_URL}/assets/third-section/costa-coffee.png`;
  const Oyo = `${process.env.PUBLIC_URL}/assets/third-section/oyo.png`;
  const Radisson = `${process.env.PUBLIC_URL}/assets/third-section/radisson.png`;

  return (
    <div className="third-section">
      <div className="arrow-left third-section-arrow">
        <img src={arrowLeft} alt="Left Arrow" />
      </div>
      <h2>Explore Our Landmark Creations</h2>
      <p>Transforming Spaces</p>
      <div className="landmark-grid">
        <div className="landmark-card">
          <img src={Infosys} alt="Infosys" />
          <p>
            <span className="highlight">Infosys</span> - Banglore
          </p>
        </div>
        <div className="landmark-card">
          <img src={McDonalds} alt="McDonald's" />
          <p>
            <span className="highlight">McDonald's</span> - Pan India
          </p>
        </div>
        <div className="landmark-card">
          <img src={Wankhede} alt="Wankhede Stadium" />
          <p>
            <span className="highlight">Wankhede Stadium</span> - Mumbai
          </p>
        </div>
        <div className="landmark-card">
          <img src={CostaCoffee} alt="Costa Coffee" />
          <p>
            <span className="highlight">Costa Coffee</span> - Pan India
          </p>
        </div>
        <div className="landmark-card">
          <img src={Oyo} alt="OYO Workspace" />
          <p>
            <span className="highlight">OYO Workspace</span> - Mumbai
          </p>
        </div>
        <div className="landmark-card">
          <img src={Radisson} alt="Radisson Blu" />
          <p>
            <span className="highlight">Radisson Blu</span> - Banglore
          </p>
        </div>
      </div>
      <div className="arrow-right third-section-arrow">
        <img src={arrowRight} alt="Right Arrow" />
      </div>
    </div>
  );
};

export default ThirdSection;
