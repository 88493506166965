import React from "react";
import "./FooterSection.css";

const FooterSection = () => {
  const logo = `${process.env.PUBLIC_URL}/assets/footer-section/logo.png`;
  const facebookIcon = `${process.env.PUBLIC_URL}/assets/footer-section/facebook.png`;
  const instagramIcon = `${process.env.PUBLIC_URL}/assets/footer-section/instagram.png`;
  const linkedinIcon = `${process.env.PUBLIC_URL}/assets/footer-section/linkedin.png`;

  return (
    <div className="footer-section">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="Pare Logo" className="footer-logo" />
          <p>
            Transform your living spaces with{" "}
            <span className="highlight">PARÉ</span> innovative, sustainable, and
            high-quality products. Let’s work together to create a space that
            reflects your unique style and creates unforgettable memories.
          </p>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-center">
          <ul className="footer-contact">
            <li>
              <span>🌐</span>{" "}
              <a
                href="https://www.pareindia.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.pareindia.com
              </a>
            </li>
            <li>
              <span>📧</span>{" "}
              <a href="mailto:hello@pareindia.com">hello@pareindia.com</a>
            </li>
            <li>
              <span>📞</span>{" "}
              <a href="tel:+918655995282">+91 86559 95282</a>
            </li>
          </ul>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-right">
          <ul className="footer-social">
            <li>
              <a
                href="https://www.facebook.com/Pareinnov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookIcon} alt="Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/pareindia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagramIcon} alt="Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/pareindia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
