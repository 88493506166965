import React, { useState } from "react";
import "./FourthSection.css";

const FourthSection = () => {
  // Product data
  const productData = {
    "INNOV+": {
      heading: "INNOV+",
      interlockingImage: `${process.env.PUBLIC_URL}/assets/fourth-section/interlocking-innov+.png`,
      products: [
        `${process.env.PUBLIC_URL}/assets/fourth-section/duo.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/soffit.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/louvers.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/vertica.png`,
      ],
    },
    "INNOV 2+": {
      heading: "INNOV2+",
      interlockingImage: `${process.env.PUBLIC_URL}/assets/fourth-section/interlocking-innov2+.png`,
      products: [
        `${process.env.PUBLIC_URL}/assets/fourth-section/wave.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/dome.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/stripes.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/unic.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/prism.png`,
      ],
    },
    "EASY+": {
      heading: "EASY+",
      interlockingImage: `${process.env.PUBLIC_URL}/assets/fourth-section/interlocking-easy+.png`,
      products: [
        `${process.env.PUBLIC_URL}/assets/fourth-section/linea.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/pyramid.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/arch.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/sheet.png`
      ],
    },
    "DURA+": {
      heading: "DURA+",
      interlockingImage: `${process.env.PUBLIC_URL}/assets/fourth-section/interlocking-dura+.png`,
      products: [
        `${process.env.PUBLIC_URL}/assets/fourth-section/norma.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/stretta.png`,
      ],
    },
    BAFFLE: {
      heading: "BAFFLE",
      interlockingImage: `${process.env.PUBLIC_URL}/assets/fourth-section/interlocking-baffle.png`,
      products: [
        `${process.env.PUBLIC_URL}/assets/fourth-section/r-50s.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/r-50m.png`,
        `${process.env.PUBLIC_URL}/assets/fourth-section/r-50l.png`,
      ],
    },
  };

  // State for selected product
  const [selectedProduct, setSelectedProduct] = useState("INNOV+");

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  const currentProduct = productData[selectedProduct];

  return (
    <div className="fourth-section">
      <div className="statistics">
        <div className="stat">
          <h2>
            18<sup>+</sup>
          </h2>
          <p>PRODUCTS</p>
        </div>
        <div className="stat">
          <h2>
            45<sup>+</sup>
          </h2>
          <p>SHADES</p>
        </div>
        <div className="stat">
          <h2>
            5K<sup>+</sup>
          </h2>
          <p>ARCHITECTURAL PROJECTS</p>
        </div>
      </div>

      <div className="product-range">
        {/* Product Menu */}
        <div className="product-menu">
          <h3>
            Product <span>Range</span>
          </h3>
          <div className="product-buttons">
            {Object.keys(productData).map((product) => (
              <button
                key={product}
                className={selectedProduct === product ? "active" : ""}
                onClick={() => handleProductChange(product)}
              >
                {product}
              </button>
            ))}
          </div>
          <div className="unique-system">
            <img
              src={currentProduct.interlockingImage}
              alt={`${currentProduct.heading} Interlocking`}
            />
            <p>
              <span>Unique Interlocking</span> System
            </p>
          </div>
        </div>

        {/* Product Showcase */}
        <div className="product-showcase">
          <h3>{currentProduct.heading}</h3>
          <div
            className="showcase-grid"
            style={{
              gridTemplateColumns: `repeat(${Math.min(
                currentProduct.products.length,
                3
              )}, 1fr)`,
            }}
          >
            {currentProduct.products.map((product, index) => (
              <div key={index} className="product-card-fourth">
                <img src={product} alt={`${currentProduct.heading} Product ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthSection;
