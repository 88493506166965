import React, { useState } from "react";
import "./FifthSection.css";

const FifthSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    location: "",
    requirement: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRequirementClick = (requirement) => {
    setFormData({ ...formData, requirement });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:5000/submit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({ name: "", phone: "", location: "", requirement: "" });
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form");
    }
  };

  const handleRedirection = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className="fifth-section"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/fifth-section/bg-img.png)`,
      }}
    >
      <div className="fifth-form-container">
        <h3>Contact Us</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name*"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone No.*"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.location}
            onChange={handleInputChange}
            required
          />
          <div className="fifth-requirement">
            <label>Requirement*</label>
            <div className="fifth-buttons">
              <button
                type="button"
                className={formData.requirement === "Wall" ? "fifth-active" : ""}
                onClick={() => handleRequirementClick("Wall")}
              >
                Wall
              </button>
              <button
                type="button"
                className={formData.requirement === "Ceiling" ? "fifth-active" : ""}
                onClick={() => handleRequirementClick("Ceiling")}
              >
                Ceiling
              </button>
              <button
                type="button"
                className={formData.requirement === "Facade" ? "fifth-active" : ""}
                onClick={() => handleRequirementClick("Facade")}
              >
                Facade
              </button>
            </div>
          </div>
          <button type="submit" className="fifth-submit-btn">
            Submit
          </button>
        </form>
      </div>
      <div className="fifth-divider"></div>
      <div className="fifth-right-section">
        <h3>Explore Our Products</h3>
        <a
          href="https://pareindia.com/exhibition/"
          target="_blank"
          rel="noopener noreferrer"
          className="fifth-download-btn"
        >
          Download <span>Brochure</span>
        </a>
        <hr />
        <h3>Quick & Easy Estimates</h3>
        <a
          href="https://pareindia.com/quotationgenerator/"
          target="_blank"
          rel="noopener noreferrer"
          className="fifth-quotation-btn"
        >
          Quotation <span>Generator</span>
        </a>
</div>
    </div>
  );
};

export default FifthSection;
