import React, { useRef, useEffect } from "react";
import "./App.css";
import FifthSection from "./components/FifthSection";
import FooterSection from "./components/FooterSection";
import FourthSection from "./components/FourthSection";
import LandingSection from "./components/LandingSection";
import SecondSection from "./components/SecondSection";
import ThirdSection from "./components/ThirdSection";

function App() {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of the section is visible
    );

    sectionsRef.current.forEach((section) => observer.observe(section));

    return () => {
      if (sectionsRef.current) {
        sectionsRef.current.forEach((section) => observer.unobserve(section));
      }
    };
  }, []);

  const addRef = (el) => {
    if (el && !sectionsRef.current.includes(el)) {
      sectionsRef.current.push(el);
    }
  };

  return (
    <div className="App">
      <div ref={addRef} className="fade-from-right">
        <LandingSection />
      </div>
      <div ref={addRef} className="fade-from-left">
        <SecondSection />
      </div>
      <div ref={addRef} className="fade-from-right">
        <ThirdSection />
      </div>
      <div ref={addRef} className="fade-from-left">
        <FourthSection />
      </div>
      <div ref={addRef} className="fade-from-right">
        <FifthSection />
      </div>
      <FooterSection />
    </div>
  );
}

export default App;
